import React from 'react';
import logo from './ticket.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <img src={logo} className="ticket" alt="ticket" />
      </header>
    </div>
  );
}

export default App;
